<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">指标名称</td>
          <td class="valueTd">
            <el-input v-model="dataForm.pokrName" placeholder="请输入省指标名称" clearable></el-input>
          </td>
          <td class="titleTd width80px" v-if="showBool">区域</td>
          <td class="valueTd" v-if="showBool">
            <region-tree :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.code">
            </region-tree>
          </td>
        </tr>
      </table>
      <div class="wxts_msg_search">
        <div class="f_t">
          <el-button-group :disabled="dataListLoading">
            <el-button size="mini" @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
            <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
          </el-button-group>
        </div>
      </div>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!-- <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column> -->
      <el-table-column prop="pokrName" header-align="center" align="center" label="指标名称">
      </el-table-column>
      <el-table-column prop="userId" header-align="center" align="center" label="指标下达人" width="150px">
        <template slot-scope="scope">
          <user :value="scope.row.userId" />
        </template>
      </el-table-column>
      <el-table-column prop="areaCode" header-align="center" align="center" label="所属区域" width="150px">
        <template slot-scope="scope">
          <org :value="scope.row.areaCode"></org>
        </template>
      </el-table-column>
      <el-table-column prop="okrState" header-align="center" align="center" label="状态" width="150px">
        <template slot-scope="scope">
          <sysDict type="execute_state" :value="scope.row.okrState"></sysDict>
        </template>
      </el-table-column>
      <el-table-column prop="resolveMonth" header-align="center" align="center" label="指标月度" width="150px">
      </el-table-column>
      <el-table-column prop="addUserNumber" header-align="center" align="center" label="新增用户数" width="150px">
      </el-table-column>
      <el-table-column prop="valueAddedIncome" header-align="center" align="center" label="增值收入" width="150px">
      </el-table-column>
      <el-table-column prop="publicBusinessIncome" header-align="center" align="center" label="大众业务收入" width="150px">
      </el-table-column>
      <el-table-column prop="developmentUser" header-align="center" align="center" label="5G用户发展用户" width="150px">
      </el-table-column>
      <!-- <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="del-button" v-if="scope.row.okrState == '01'" @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './add-or-update'
import { getList, deleteData, getexecuteUpdate, exportToExcel } from '@/api/dataflow/olxcokrmanagemain.js'
export default {
  data() {
    return {
      dataForm: {
        pokrName: '',
        code: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    this.getDataList();
  },
  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    regionCode: {
      get() {
        const newregionId = this.$store.state.user.regionCode;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return newgridId;
        } else {
          return ''
        }
      }
    },
    showBool: function () {
      let str = this.regionCode
      str = str.slice(4)
      if (this.regionId == '1000' || str == 'f') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    refresh() {
      // this.$router.push({name:'dataflow_olxcokrmanagemain',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      const code = this.$store.state.user.regionCode !== undefined ? this.$store.state.user.regionCode : '0'
      const params = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'pokrName': this.dataForm.pokrName,
        'areaCode': code,
        'code': this.dataForm.code
      }
      getList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    //查看
    viewHandle(id, flag) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, flag)
      })
    },
    //执行
    execute(id) {
      this.$confirm(`确定要执行该任务`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        getexecuteUpdate(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '接受成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    },
    // 删除
    deleteHandle(row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})

      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    },
    exportData() {
      let config = {
        areaCode: this.gridId !== '' ? this.gridId : this.regionCode,
        pokrName: this.dataForm.pokrName,
        code: this.dataForm.code
      }
      exportToExcel(config);
    },
  }
}
</script>
