<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : (flag == 'look' ? '查看' : '修改')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @closed="$emit('closeX')">
    <el-form :model="dataForm" :disabled="flag=='look'" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="省指标" prop="pName">
      <el-input v-model="dataForm.pName" disabled placeholder="省指标"></el-input>
    </el-form-item>
    <el-form-item label="指标接受人"  prop="userName">
      <el-input v-model="dataForm.userName" disabled placeholder="指标接受人"></el-input>
    </el-form-item>
    <el-form-item label="公司级别" prop="areaType">
      <sysDictSelect v-model="dataForm.areaType" dictType="org_type" placeholder="公司级别"></sysDictSelect>
    </el-form-item>
    <el-form-item label="下级区域" prop="checkAll">
<!--      <sysDictSelect v-model="dataForm.areaCode" placeholder="下级区域"></sysDictSelect>-->
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

      <el-form-item label="月份" prop="resolveMonth">
        <el-date-picker
            v-model="dataForm.resolveMonth"
            type="month"
            placeholder="选择月">
        </el-date-picker>
<!--          <el-date-picker-->
<!--              type="months"-->
<!--              v-model="dataForm.resolveMonth"-->
<!--              placeholder="选择一个或多个月份">-->
<!--          </el-date-picker>-->
      </el-form-item>
      <el-form-item label="新增用户" prop="addUserNumber" >
        <el-input-number  v-model="dataForm.addUserNumber"  :min="0" placeholder="请输入新增用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="增值收入" prop="valueAddedIncome" >
        <el-input-number  v-model="dataForm.valueAddedIncome"  :min="0" placeholder="请输入增值收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="大众业务收入" prop="publicBusinessIncome" >
        <el-input-number  v-model="dataForm.publicBusinessIncome"  :min="0" placeholder="请输入大众业务收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="5G用户发展用户" prop="developmentUser" >
        <el-input-number  v-model="dataForm.developmentUser"  :min="0" placeholder="请输入5G用户发展用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="状态" prop="okrState">
      <sysDictSelect v-model="dataForm.okrState" disabled dictType="management_index_state" placeholder="状态"></sysDictSelect>
    </el-form-item>
<!--    <el-form-item label="接受时间" prop="reciveDate">-->
<!--      <el-date-picker-->
<!--          v-model="dataForm.reciveDate"-->
<!--          type="date"-->
<!--          value-format='yyyy-MM-dd'-->
<!--          placeholder="选择日期">-->
<!--      </el-date-picker>-->
<!--    </el-form-item>-->
    <el-form-item label="分解时间" prop="resolveDate">
      <el-date-picker
          v-model="dataForm.resolveDate"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择日期">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="执行时间" prop="execDate">
      <el-date-picker
          v-model="dataForm.execDate"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择日期">
      </el-date-picker>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olxcokrmanagemain.js'
import { childCode } from '@/api/sys/region.js'
import {dateFormat} from "@/utils";
import {getAreaName} from '@/api/dataflow/olxcokrmanagemain.js';
const cityOptions = [];
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        flag:'',
        checkAll: false,
        checkedCities: [],
        cities: cityOptions,
        isIndeterminate: true,
        dataForm: {
          id: null,
          pId: '',
          pName: '',
          userName: '',
          areaType: '',
          areaCode: '',
          okrState: '',
          reciveDate: '',
          resolveDate: '',
          execDate: '',
          addUserNumber: '',
          resolveMonth: '',
          valueAddedIncome: '',
          publicBusinessIncome: '',
          developmentUser: '',
        },
        dataRule: {
          pId: [
            { required: true, message: '省指标不能为空', trigger: 'blur' }
          ],
          userName: [
            { required: true, message: '指标接受人不能为空', trigger: 'blur' }
          ],
          // areaType: [
          //   { required: true, message: '公司级别', trigger: 'blur' }
          // ],
          // checkAll: [
          //   { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
          // ],
          // okrState: [
          //   { required: true, message: '状态', trigger: 'blur' }
          // ],
          // reciveDate: [
          //   { required: true, message: '接受时间不能为空', trigger: 'blur' }
          // ],
          // resolveDate: [
          //   { required: true, message: '分解时间不能为空', trigger: 'blur' }
          // ],
          execDate: [
            { required: true, message: '执行时间不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      /*check(rule, value, callback){
        console.log(this.checkedCities.length)
        if (this.checkedCities.length == 0 ) {
          console.log("000000000")
          return callback(new Error('下属区域不能为空'));
        }
      },*/
      init (id,flag,pokId,orkName) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.flag = flag
        this.dataForm.pId = pokId
        this.dataForm.pName = orkName
        this.dataForm.okrState = '03'

        console.log("this.dataForm.pName")
        console.log(this.dataForm.pName)
        console.log("orkName")
        console.log(orkName)

        //获取登录时的code
        const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
        this.childCodeName(code)
        this.dataForm.userName = this.$cookie.get("Username");
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.pId = data.data.pId
                this.dataForm.pName = data.data.pName
                this.dataForm.userName = data.data.userName
                this.dataForm.areaType = data.data.areaType
                this.dataForm.areaCode = data.data.areaCode
                this.dataForm.addUserNumber = data.data.addUserNumber
                this.dataForm.resolveMonth = data.data.resolveMonth
                this.dataForm.valueAddedIncome = data.data.valueAddedIncome
                this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
                this.dataForm.developmentUser = data.data.developmentUser
                this.dataForm.okrState = data.data.okrState
                this.dataForm.reciveDate = data.data.reciveDate
                this.dataForm.resolveDate = data.data.resolveDate
                this.dataForm.execDate = data.data.execDate
                var string = data.data.areaCode
                this.checkedCities = string.split(',') //字符串按逗号分隔成数组

              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },

      //下属复选框
      handleCheckAllChange(val) {
        this.checkedCities = val ? cityOptions : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
      //获取下级区域
      childCodeName(code) {
        if (cityOptions.length === 0) {
          childCode(code).then(({data}) => {
            for (var i = 0; i <= data.data.length -1; i++) {
              cityOptions.push(data.data[i].name);
            }
          })
        }
      },

      // 表单提交
      dataFormSubmit () {
        this.dataForm.areaCode = this.checkedCities.join(",");
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
              'pokrId': this.dataForm.pId,
              'pokrName': this.dataForm.pName,
              'userName': this.dataForm.userName,
              'areaType': this.dataForm.areaType,
              'addUserNumber': this.dataForm.addUserNumber,
              'resolveMonth': this.dataForm.resolveMonth,
              'valueAddedIncome': this.dataForm.valueAddedIncome,
              'publicBusinessIncome': this.dataForm.publicBusinessIncome,
              'developmentUser': this.dataForm.developmentUser,
              'areaCode': this.dataForm.areaCode,
              'okrState': this.dataForm.okrState,
              'reciveDate': this.dataForm.reciveDate,
              'resolveDate': this.dataForm.resolveDate,
              'execDate': this.dataForm.execDate
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
